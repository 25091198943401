import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Rightcontainer = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const [WebSitesettings, setWebsiteSettings] = useState("");

  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  };
  document.title = WebSitesettings ? WebSitesettings.WebTitle : "win real cash";
  //console.log(WebSitesettings);
  useEffect(() => {
    fetchData();
  }, []);
  const gametittle = "RDX Ludo";
  return (
    <div>
      <div className="rightContainer">
        <div className="rcBanner flex-center ">
          <picture className="d-flex rcBanner-img-container animate__bounce infinite mb-5">
            <img
              src="/Images/LandingPage_img/Header_profile.png"
              alt="nothing"
            />
          </picture>
          <div
            className="rcBanner-text "
            style={{ fontWeight: "bolder", marginTop: "100px", bottom: "25%" }}
          >
            {gametittle}
            <span
              className="rcBanner-text-bold"
              style={{ fontWeight: "normal" }}
            >
              {" "}
              Win Real Cash
            </span>
            <br />
            <a className="cxy" href="">
              {" "}
              <span style={{ color: "#3A427D" }}> Download Now </span>
            </a>

            <div className="right_download">
              <Link to="/" className="app_download">
                <picture>
                  <img
                    src="/Images/LandingPage_img/gplay_store.png"
                    alt="apk"
                  />
                </picture>
              </Link>
              <Link to="/" className="app_download ml-2">
                <picture>
                  <img
                    src="/Images/LandingPage_img/apple_store.png"
                    alt="apk"
                  />
                </picture>
              </Link>
            </div>
          </div>
          <div className="rcBanner-footer">
            For best experience, open&nbsp;
            <a
              href="/"
              style={{
                color: "rgb(44, 44, 44)",
                fontWeight: 500,
                textDecoration: "none",
              }}
            >
              {gametittle}
            </a>
            &nbsp;on&nbsp;
            <img src={process.env.PUBLIC_URL + "/Images/chrome.png"} alt="" />
            &nbsp;chrome mobile
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rightcontainer;
