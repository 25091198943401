import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import "../Components/Component-css/Header.css";
import css from "./Component-css/Nav.module.css";
import Downloadbutton from "../Components/Downloadbutton";

const w3_close = () => {
  const width = document.getElementById("mySidebar").offsetWidth;
  document.getElementById("mySidebar").style.left = `-${width}px`;
  document.getElementById("sidebarOverlay").style.display = "none";
};
const w3_open = () => {
  document.getElementById("mySidebar").style.left = "0";
  document.getElementById("sidebarOverlay").style.display = "block";
};

const Header = ({ user, loggedIn }) => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const history = useHistory();

  let access_token = localStorage.getItem("token");
  access_token = localStorage.getItem("token");

  const [WebSitesettings, setWebsiteSettings] = useState("");

  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    if (data.isWebsiteOn == false) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Website is currently under maintenance. Please try again later.",
      });
      logout();
      return;
    }
    return setWebsiteSettings(data);
  };

  document.title = WebSitesettings
    ? WebSitesettings.WebTitle
    : "HiPLAY WIN REAL CASH";
  //console.log(WebSitesettings);
  useEffect(() => {
    fetchData();
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }, []);

  const logout = () => {
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `logout`,
        {
          headers: headers,
        },
        { headers }
      )
      .then((res) => {
        // setUser(res.data)
        localStorage.removeItem("token");
        window.location.reload();
        history.push("/");
      })
      .catch((e) => {
        if (e.response?.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
        }
      });
  };

  return (
    <div>
      {access_token ? (
        <React.Fragment>
          <div id="sidebarOverlay" onClick={w3_close}></div>
          <div
            className="w3-sidebar w3-bar-block"
            id="mySidebar"
            style={{ paddingBottom: "70px" }}
          >
            <Link
              to={"/Profile"}
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                {user && user.avatar ? (
                  <img
                    width="30px"
                    height="30px"
                    src={`https://kycztm.dreamadda.in/${user && user.avatar}`}
                    alt="profile"
                    style={{ borderRadius: "50px" }}
                  />
                ) : (
                  <img
                    // src={baseUrl + WebSitesettings.SmallLogo}
                    src="/Images/avatars/Avatar3.png"
                    width="25px"
                    height="25px"
                    alt="profile"
                  />
                )}
              </picture>
              <div
                style={{
                  marginLeft: ".5rem",
                  fontWeight: "700",
                  fontSize: "20px",
                }}
              >
                My Profile
              </div>

              {/* <picture className="sideNav-arrow">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/Images/global-black-chevronRight.png"
                  }
                  alt=""
                />
              </picture> */}

              <div className="sideNav-divider"></div>
            </Link>
            <Link
              to={"/landing"}
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                <i className="fa-solid fa-2x fa-indian-rupee-sign"></i>
              </picture>
              <div
                style={{
                  marginLeft: ".5rem",
                  fontWeight: "700",
                  fontSize: "20px",
                }}
              >
                Win cash
              </div>

              {/* <picture className="sideNav-arrow">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/Images/global-black-chevronRight.png"
                  }
                  alt=""
                />
              </picture> */}
              <div className="sideNav-divider"></div>
            </Link>
            <Link
              to={"/wallet"}
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                <i class="fa-solid fa-2x fa-wallet"></i>
              </picture>
              <div
                style={{
                  marginLeft: ".5rem",
                  fontWeight: "700",
                  fontSize: "20px",
                }}
              >
                My wallet
              </div>

              {/* <picture className="sideNav-arrow">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/Images/global-black-chevronRight.png"
                  }
                  alt=""
                />
              </picture> */}

              <div className="sideNav-divider"></div>
            </Link>

            <Link
              to={"/Gamehistory"}
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                <i class="fa-solid fa-2x  fa-gamepad"></i>
              </picture>
              <div
                style={{
                  marginLeft: ".5rem",
                  fontWeight: "700",
                  fontSize: "20px",
                }}
              >
                Game History
              </div>
              {/* 
              <picture className="sideNav-arrow">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/Images/global-black-chevronRight.png"
                  }
                  alt=""
                />
              </picture> */}

              <div className="sideNav-divider"></div>
            </Link>

            <Link
              to="/transaction-history"
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                <i class="fa-solid fa-2x  fa-clock-rotate-left"></i>
              </picture>
              <div
                style={{
                  marginLeft: ".5rem",
                  fontWeight: "700",
                  fontSize: "20px",
                }}
              >
                Transaction History
              </div>

              {/* <picture className="sideNav-arrow">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/Images/global-black-chevronRight.png"
                  }
                  alt=""
                />
              </picture> */}

              <div className="sideNav-divider"></div>
            </Link>

            <Link
              to={"/refer"}
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                <i class="fa-solid fa-2x  fa-share-nodes"></i>
              </picture>
              <div
                style={{
                  marginLeft: ".5rem",
                  fontWeight: "700",
                  fontSize: "20px",
                }}
              >
                Refer and Earn
              </div>

              {/* <picture className="sideNav-arrow">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/Images/global-black-chevronRight.png"
                  }
                  alt=""
                />
              </picture> */}

              <div className="sideNav-divider"></div>
            </Link>

            <Link
              to={"/Referral-history"}
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                <i class="fa-solid fa-2x  fa-square-up-right"></i>
              </picture>
              <div
                style={{
                  marginLeft: ".5rem",
                  fontWeight: "700",
                  fontSize: "20px",
                }}
              >
                Refer History
              </div>

              {/* <picture className="sideNav-arrow">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/Images/global-black-chevronRight.png"
                  }
                  alt=""
                />
              </picture> */}

              <div className="sideNav-divider"></div>
            </Link>

            <Link
              to={"/Notification"}
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                <i class="fa-solid fa-2x  fa-bell"></i>
              </picture>
              <div
                style={{
                  marginLeft: ".5rem",
                  fontWeight: "700",
                  fontSize: "20px",
                }}
              >
                Notification
              </div>

              {/* <picture className="sideNav-arrow">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/Images/global-black-chevronRight.png"
                  }
                  alt=""
                />
              </picture> */}

              <div className="sideNav-divider"></div>
            </Link>

            <Link
              to={"/support"}
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                <i class="fa-solid fa-2x fa-headset"></i>
              </picture>
              <div
                style={{
                  marginLeft: ".5rem",
                  fontWeight: "700",
                  fontSize: "20px",
                }}
              >
                Support
              </div>
              {/* 
              <picture className="sideNav-arrow">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/Images/global-black-chevronRight.png"
                  }
                  alt=""
                />
              </picture> */}

              <div className="sideNav-divider"></div>
            </Link>
            <Link
              to={"/all-policy"}
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                <img
                  src={process.env.PUBLIC_URL + "/Images/terms.png"}
                  alt=""
                />
              </picture>
              <div
                style={{
                  marginLeft: ".5rem",
                  fontWeight: "700",
                  fontSize: "20px",
                }}
              >
                All Policy
              </div>
              {/*  <picture className="sideNav-arrow">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/Images/global-black-chevronRight.png"
                  }
                  alt=""
                /> 
              </picture>*/}
              {/* <div className="sideNav-divider"></div> */}
            </Link>

            {/*   <Link className="w3-bar-item w3-button" to="!/" onClick={(e) => logout(e)}>
          <picture className="icon">
            <img alt="img" src={process.env.PUBLIC_URL + '/Images/logout.png'} />
          </picture>
          <div style={{ marginLeft: '.5rem' }}>
            Logout
          </div>

        </Link> */}
          </div>

          <div className="w3-teal">
            <div className="w3-container ">
              <div className={`${css.headerContainer} `}>
                <button
                  className="w3-button w3-xlarge float-left"
                  onClick={w3_open}
                  id="hambergar"
                >
                  <picture className={`${css.sideNavIcon} mr-0`}>
                    <img
                      src="/Images/LandingPage_img/bar.png"
                      className="snip-img"
                      alt=""
                    />
                    {/* <i style={{ color: "black", fontSize: "2rem", fontWeight: "700" }} className="fa-solid fa-bars"></i> */}
                  </picture>
                </button>
                <Link to="/">
                  <picture className={`ml-2 ${css.navLogo} d-flex`}>
                    <img
                      src="/Images/LandingPage_img/Header_profile.png"
                      className="snip-img"
                      alt=""
                    />
                  </picture>
                </Link>
                <div>
                  <div className={`${css.menu_items}`}>
                    <div className="bell">
                      <div className="downloadButton mr-2">
                        <Downloadbutton />
                      </div>
                    </div>
                    <Link className={`${css.box}`} to="/Addcase">
                      <picture className={`${css.moneyIcon_container}`}>
                        <i
                          style={{
                            color: "#0074c8",
                            paddingLeft: "5px",
                            fontSize: "20px",
                          }}
                          className="fa-solid fa-wallet"
                        ></i>
                      </picture>
                      <div className="mt-1 ml-1">
                        {/* <div className={`${css.moneyBox_header}`}>Cash</div> */}
                        <div
                          className={`${css.moneyBox_text} text-dark px-1`}
                          style={{ fontSize: "16px" }}
                        >
                          {user && user.Wallet_balance}
                        </div>
                      </div>
                      <div className="bg-dark-custum p-2">
                        <i
                          style={{ color: "#fff", paddingLeft: "5px" }}
                          className="fa-solid fa-plus"
                        ></i>
                      </div>
                    </Link>
                    {/* <Link
                      className={`${css.box} ml-2`}
                      to="/redeem/refer"

                    >
                      <picture className={`${css.moneyIcon_container}`}>
                        <i style={{ color: "#ffffff", paddingLeft: "5px" }} class="fa-solid fa-gift"></i>
                      </picture>
                      <div className="mt-1 ml-1">
                        <div className={`${css.moneyBox_header}`}>Earning</div>
                        <div className={`${css.moneyBox_text}`}>
                          {user && user.referral_wallet}
                        </div>
                      </div>
                    </Link> */}
                  </div>
                  <span className="mx-5"></span>
                </div>
                <span className="mx-5"></span>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div className="w3-teal ">
          <div className="w3-container ">
            <div className={`${css.headerContainer} justify-content-between`}>
              <Link to="/">
                <picture className={`ml-2 ${css.navLogo} d-flex`}>
                  <img
                    src="/Images/LandingPage_img/Header_profile.png"
                    className="snip-img"
                    alt=""
                  />
                </picture>
              </Link>

              <div className={`ml-5`}>
                <Link type="button" className="login-btn" to="/login">
                  LOGIN
                </Link>
              </div>
            </div>
            <div className={`${css.headerContainer} justify-content-between`}>
              <Link to="/">
                <picture className={`ml-2 ${css.navLogo} d-flex`}>
                  <img
                    src="/Images/LandingPage_img/Header_profile.png"
                    className="snip-img"
                    alt=""
                  />
                </picture>
              </Link>

              <div className={`ml-1 d-flex align-items-center`}>
                <div className="downloadButton">
                  <Downloadbutton />
                </div>
                <Link type="button" className="login-btn ml-2" to="/login">
                  LOGIN
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
