import React, { useState } from "react";
import { Link } from "react-router-dom";
import Rightcontainer from "../Components/Rightcontainer";
import "../css/terms.css";

const All_policy = () => {
  const [data, setData] = useState();
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    var baseUrl = beckendLiveApiUrl;
  }

  return (
    <div>
      <div
        className="leftContainer"
        style={{ minHeight: "100vh", height: "100%" }}
      >
        <div className="mt-5 policy_details_link py-4 px-3">
          <Link to="/term-condition">
            <span
              style={{ color: "#959595" }}
              className="col giveHover policy_details d-block"
            >
              Terms & Conditions
            </span>
          </Link>
          <Link to="/PrivacyPolicy">
            <span
              style={{ color: "#959595" }}
              className="col giveHover policy_details d-block"
            >
              Privacy policy
            </span>
          </Link>

          <Link to="/refund-policy">
            <span
              style={{ color: "#959595" }}
              className="col giveHover policy_details d-block"
            >
              Refund/Cancellatioin Policy
            </span>
          </Link>

          <Link to="/contact-us">
            {" "}
            <span
              style={{ color: "#959595" }}
              className="col giveHover policy_details d-block"
            >
              Contact Us
            </span>{" "}
          </Link>

          <Link to="/responsible-gaming">
            {" "}
            <span
              className="col giveHover policy_details d-block"
              style={{ color: "#959595" }}
            >
              Responsible Gaming{" "}
            </span>
          </Link>
          <Link to="About">
            {" "}
            <span
              style={{ color: "#959595" }}
              className="col giveHover policy_details d-block"
            >
              About Us
            </span>{" "}
          </Link>
        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};
export default All_policy;
